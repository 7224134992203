<template>
  <v-main>
    <template v-if="viewDataIsLoaded">
      <v-app-bar
        fixed
        elevate-on-scroll
        dark
        color="primary"
      >
        <v-btn
          icon
          @click="goHome()"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Bokning</v-toolbar-title>
        <v-spacer></v-spacer>
        <!--
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="saveBooking"
          >
            Spara
          </v-btn>
        </v-toolbar-items>
        -->
      </v-app-bar>
      <v-container>
        <v-row class="pt-16">
          <v-col
            cols="12" sm="6"
            class="pt-4 pb-16"
          >
            <v-card
              class="mx-auto pa-0 mb-4"
              outlined
            >
              <v-card-title
                class="d-flex py-2 xCardTitle"
              >
                <div class="text-overline">Bokning</div>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text
                class="pa-0"
              >
                <v-simple-table class="xSimpleTable">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(dataRow, index) of bookingDataRows"
                        :key="index"
                        :class="{ 'alert' : dataRow.alert }"
                      >
                        <td>
                          <div class="text-subtitle-2">{{ dataRow.label }}</div>
                        </td>
                        <td>
                          <div
                            v-for="(value, index) of dataRow.values"
                            :key="index"
                            class="text-body"
                          >
                            {{ value }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12" sm="6"
            class="pt-4 pb-16"
          >
            <v-card
              class="mx-auto pa-0 mb-4"
              outlined
              v-if="receipt.has"
            >
              <v-card-title
                class="d-flex py-2 xCardTitle"
              >
                <div class="text-overline">Kvitto</div>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text
                class="pa-0"
              >
                <v-simple-table class="xSimpleTable">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(row, index) of receipt.rows"
                        :key="index"
                      >
                        <td>
                          {{ row.text }}
                        </td>
                        <td class="text-right">
                          {{ row.textRight }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">
                          Totalt:
                        </td>
                        <td class="text-right font-weight-bold">
                          {{ receipt.amount }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="d-flex justify-start px-4">
          <v-btn
            v-if="!booking.isCancelled"
            color="danger"
            @click="cancelBooking()"
          >
            Avboka
          </v-btn>
        </div>
      </v-container>
    </template>
    <!--snack-bar-->
    <app-snack-bar></app-snack-bar>
    <!--loader-->
    <v-overlay
      :value="loading"
      opacity="0.8"
      z-index="1001"
    >
      <v-progress-circular
        :size="60"
        color="secondary"
        indeterminate
      >
      </v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import axios from 'axios'
import SnackBar from '../components/SnackBar'
import { snackBarBus } from '../main'
import { mapGetters } from 'vuex'
import { generalMixin } from '../mixins/general'
import { urlMixin } from '../mixins/url'
import { dateMixin } from '../mixins/date'
import { arrayMixin } from '../mixins/array'

export default {
  name: 'SaloonEdit',
  components: {
    'app-snack-bar': SnackBar,
  },
  mixins: [generalMixin, urlMixin, dateMixin, arrayMixin],
  props: {
    id: {
      type: Number,
      default: 0
    },
    referer: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loading: false,
    viewDataIsLoaded: false,
    booking: {},
    bookingDataRows: [],
    receipt: {
      rows: []
    }
  }),
  computed: {
    ...mapGetters(['agent']),
  },
  watch: {
  },
  methods: {
    getData() {
      console.log('getData')
      if (this.loading) {
        return
      }
      this.loading = true
      let relUrl = '/admin/booking/' + this.agent + '/' + this.id
      const url = this.url_composeApiUrl(relUrl)
      axios.get(url)
        .then(response => {
          console.log('response', response)
          this.loading = false
          if (response.data.success) {
            //success
            this.onData(response.data.returnData)
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.loading = false
        })
    },
    onData(data) {
      this.booking = data.booking
      this.bookingDataRows = data.bookingDataRows
      this.receipt = data.receipt
      //final
      this.viewDataIsLoaded = true
    },
    goHome() {
      //go to home with event tab active, like this
      switch (this.$route.query.referer) {
        case 'event':
          this.$router.replace({ name: 'event-bookings', params: { id: this.booking.evnId } })
        break
        default:
          this.$router.replace({ name: 'home' })
      }
    },
    cancelBooking() {
      if (this.loading) {
        return
      }
      if (!confirm("Vill du verkligen avboka denna bokning?")) {
        return
      }
      this.loading = true
      const postData = {
        agent: this.agent,
        id: this.booking.id
      }
      const config = {}
      let relUrl = '/admin/cancel-booking'
      const url = this.url_composeApiUrl(relUrl)
      console.log('post', postData)
      axios.post(url, postData, config)
        .then(response => {
          console.log('response', response)
          this.loading = false
          if (response.data.success) {
            //success
            this.onData(response.data.returnData)
          } else if (this.onNonSuccessApiCall(response.data.returnData)) {
            snackBarBus.$emit('message', this.generalParseErrorArray(response.data.returnData.error_ar))
          }
        })
        .catch(error => {
          snackBarBus.$emit('message', error.toString())
          this.loading = false
        })
    },
  },
  created() {
    this.getData()
  }
}
</script>
